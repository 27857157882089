@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Appview {
  background-color: #282c34;
  height: 100%;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.winner {
  background-color: #13aa52;
  color: black;
  font-size: 6vh;
  text-align: center;
}

.board-holder {
  position: relative;
  height: 100vw;
}

.board {
  overflow: hidden;
  position: absolute;
  top: 8vw;
  left: 12vw;
  padding: 0;
  width: 76vw;
  aspect-ratio: 1/1;
  border-radius: 50%;
  list-style: none;
  z-index: 1;
}
.dartboard-img {
  position: absolute;
  padding: 0;
  width: 100vw;
  height: 100vw;
  z-index: 0;
}

.bull {
  position: relative;
  margin-left: 27vw;
  margin-top: 28vw;
  border: solid 6vw black;
  padding: 0;
  width: 10vw;
  height: 10vw;
  list-style: none;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}

.bull:hover {
  background: #13aa52;
}

@-webkit-keyframes fill {
  to {
    background-size: 100% 0;
  }
}

@keyframes fill {
  to {
    background-size: 100% 0;
  }
}

.slice {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  width: 38vw;
  aspect-ratio: 1/1;
  transform-origin: 0% 100%;
  border: solid 1px black;
}

.slice:nth-child(1) {
  transform: rotate(9deg) skewY(-70deg);
}

.slice:nth-child(2) {
  transform: rotate(27deg) skewY(-70deg);
}

.slice:nth-child(3) {
  transform: rotate(45deg) skewY(-70deg);
}

.slice:nth-child(4) {
  transform: rotate(63deg) skewY(-70deg);
}

.slice:nth-child(5) {
  transform: rotate(81deg) skewY(-70deg);
}

.slice:nth-child(6) {
  transform: rotate(99deg) skewY(-70deg);
}

.slice:nth-child(7) {
  transform: rotate(117deg) skewY(-70deg);
}

.slice:nth-child(8) {
  transform: rotate(135deg) skewY(-70deg);
}

.slice:nth-child(9) {
  transform: rotate(153deg) skewY(-70deg);
}

.slice:nth-child(10) {
  transform: rotate(171deg) skewY(-70deg);
}

.slice:nth-child(11) {
  transform: rotate(189deg) skewY(-70deg);
}

.slice:nth-child(12) {
  transform: rotate(207deg) skewY(-70deg);
}

.slice:nth-child(13) {
  transform: rotate(225deg) skewY(-70deg);
}

.slice:nth-child(14) {
  transform: rotate(243deg) skewY(-70deg);
}

.slice:nth-child(15) {
  transform: rotate(261deg) skewY(-70deg);
}

.slice:nth-child(16) {
  transform: rotate(279deg) skewY(-70deg);
}

.slice:nth-child(17) {
  transform: rotate(297deg) skewY(-70deg);
}

.slice:nth-child(18) {
  transform: rotate(315deg) skewY(-70deg);
}

.slice:nth-child(19) {
  transform: rotate(333deg) skewY(-70deg);
}

.slice:nth-child(20) {
  transform: rotate(351deg) skewY(-70deg);
  border-bottom: solid 2px black;
}

.slice-contents {
  position: relative;
  width: 200%;
  height: 200%;
  background: black;
  color: whitesmoke;
}

.slice-contents:hover {
  background: #13aa52;
}

.slice-contents-wheat {
  position: relative;
  width: 200%;
  height: 200%;
  color: black;
  background: wheat;
}

.slice-contents-wheat:hover {
  background: #13aa52;
}

.header {
  overflow-y: hidden;
  width: 100%;
  min-height: 13vh;
  display: inline-flex;
}

.name {
  text-align: center;
  width: 40%;
}

.turn_holder {
  width: 10%;
}
.turn {
  background-color: #13aa52;
  position: absolute;
  height: 8vh;
  width: 10vw;
  border-top: 13px solid #13aa52;
  transform: translateY(-13px);
  text-align: center;
  font-size: xx-large;
  border-radius: 0 0 50% 50%;
}

.button-normal {
  width: 100%;
  background-color: #13aa52;
  border: 1px solid #13aa52;
  border-radius: 9px;
  color: black;
  cursor: pointer;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont,
    sans-serif;
  font-size: 1vh;
  padding: 10px 10px 10px 10px;
  text-align: center;
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
}

.dart-img-0 {
  height: 0.5vh;
  float: right;
}
.dart-img {
  height: 2vh;
  float: right;
  transform: rotate(-20deg);
}
.dart-img-main {
  position: absolute;
  height: 20vh;
  top: 40vh;
  left: -10vh;
  float: right;
  transform: rotate(-90deg);
}

.button-dart {
  background-color: #13aa52;
  border: 2px solid #13aa52;
  width: 33vw;
  height: 6vh;
  border-radius: 4px;
  font-size: 3vh;
  font-weight: 400;
  outline: none;
  outline: 0;
  text-align: center;
  transition: transform 200ms;
  user-select: none;
  -webkit-user-select: none;
  z-index: 1;
}

.button-dart-none {
  background-color: #13aa52;
  border: 2px solid #13aa52;
  width: 33vw;
  height: 6vh;
  content: none;
  border-radius: 4px;
  font-size: 3vh;
  font-weight: 400;
  outline: none;
  outline: 0;
  text-align: center;
  transform: translateY(0);
  transition: transform 200ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  z-index: 1;
}

.dart-sum {
  background-color: #13aa52;
  width: 99vw;
  height: 6vh;
  border-radius: 4px;
  font-weight: 400;
  outline: none;
  outline: 0;
  text-align: center;
  z-index: 0;
}

.darts {
  width: 100vw;
  display: flex;
  flex-direction: row;
}

.multiplier-fullscreen {
  height: 101%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #282c34;
  z-index: 10;
}

.double {
  color: white;
  background-color: #13aa52;
  height: 40%;
  width: 100%;
}

.triple {
  color: white;
  background-color: red;
  height: 50%;
  width: 100%;
}

.multiplier-counter {
  background-color: red;
  height: 10px;
  width: 0px;
  position: relative;
  border: none;
  top: 0;
  left: 0;
  max-width: 100%;
}

button:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 9px 0;
  transform: translateY(-2px);
}

@media (min-width: 768px) {
  button {
    padding: 10px 30px;
  }
}

.noselect {
  user-select: none;
  pointer-events: none;
}

.smally {
  font-size: medium;
  color: green;
}

.active {
  font-size: 4vh;
  color: #13aa52;
}

.not-active {
  font-size: 3vh;
  color: white;
}

h1 {
  margin: 0 0 0 0;
  user-select: none;
  pointer-events: none;
}

.scoreBoard {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #282c34;
  z-index: 2;
}

.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-bottom: 20vh;
  margin-top: 20vh;
  width: 50%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 4px solid gray;
  outline: 0;
  font-size: 1.3rem;
  color: white;
  padding: 7px 10px;
  background: transparent;
  transition: border-color 0.2s;
}

.form__field::placeholder {
  color: transparent;
}

.form__field::placeholder-shown ~ .form__label {
  font-size: 1.3rem;
  cursor: text;
  top: 20px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: gray;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: whitesmoke;
  font-weight: 700;
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, white, #13aa52);
  border-image-slice: 1;
}

.table-dark {
  width: 100%;
  color: #fff;
  text-align: center;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}
